import { mapActions } from 'vuex'
import FavouriteMixin from '@/modules/forms/mixins/FavouriteMixin'
import ToastMessage from '@/utils/toast_message'

export default {
  mixins: [FavouriteMixin],
  methods: {
    ...mapActions('forms', ['deleteForm']),
    async processDelete (formId, formName) {
      this.loading = true
      const checkDelete = await this.deleteForm(formId)
      let toastText
      const toastMeta = {
        variant: '',
        title: ''
      }
      const h = this.$createElement
      if (checkDelete === true) {
        this.unFavouriteThis(this.$FORM('FAVOURITE').TYPE, formId)
        ToastMessage.showDeletedSuccess({
          vueInstance: this,
          name: formName
        })
      } else {
        toastText = h(
          'p', {
            class: ['mb-0']
          },
          [
            `${this.$FORM('TEXT').TOAST.TEXT.FAIL_DELETE} `,
            h('strong', {}, formName)
          ]
        )
        toastMeta.variant = 'danger'
        toastMeta.title = this.$FORM('TEXT').TOAST.TITLE.ERROR
        this.$root.$bvToast.toast(toastText, {
          variant: toastMeta.variant,
          title: toastMeta.title,
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    }
  }
}
