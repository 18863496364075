<template>
  <div class="create-edit-composite-form">
    <portal to="top-bar">
      <div class="title text-capitalize">
        <h1>{{ titleText }}</h1>
      </div>
    </portal>

    <ParentCanvas
      usage="form"
      ref="ParentCanvas"
      :actionKey="actionKey"
      :loading="loading"
      :items="items"
      :compositeForm="compositeForm"
      :slidingTab="slidingTab"
      :accessGroupsError="accessGroupsError"
      :titleErrorState="formTitleError.state"
      :formTitleError="formTitleError"
      :formMode="mode"
      @setFormPermittedGroups="setFormPermittedGroups"
      @changeItemsDirectly="changeItemsDirectly"
      @openFormModal="openFormModal"
      @setPrimary="setPrimary"
      @setMandatory="setMandatory"
      @submitData="submitCompositeForm"
      @checkItem="checkMandatoryItem"
      @isLinking="isLinking"
    />

    <!-- MODAL FOR EDITING FORM -->
    <div class="individual-modal-form">
      <b-modal id="create-form-modal" size="xl" hide-footer no-close-on-backdrop scrollable :title="$FORM('TEXT').TITLE.EDIT_FORM">
        <div v-if="modalLoading" class="text-center">
          <b-spinner :label="`${$LOCAL('COMMON_WORD').LOADING}..`"></b-spinner>
        </div>
        <transition v-else name="slide-down">
          <b-card class="shadow-sm text-left va-card">
            <HeaderEdit
              class="d-flex justify-content-center mb-2"
              ref="HeaderEdit"
              @resetError="resetError"
              :formMode="this.$route.meta.mode"
              :theHeader="formData"
              :error="individualFormError"
              type="composite-form"
              :enableFormBuilder="true"
              :headerOf="$FORM('HEADER_TYPE').FORM" />
            <FormField
              :ref="$FORM('REFS').FORM_FIELD"
              :loading="loading"
              :mode="this.$route.meta.mode"
              :formData="formData"
              :error="error"
              :isValidatePermittedGroups="false"
              :missingRequiredFieldWarn="$FORM('ERROR').MISSING_REQUIRED_FIELD"
              :emptyTabWarn="$FORM('ERROR').EMPTYTAB"
              :emptyPermittedGroupsWarn="$FORM('ERROR').EMPTY_PERMITTED_GROUPS"
              :enableFormBuilder="true"
              @submitDynamicForm="submitDynamicForm"
              @setError="setError"
              @resetError="resetError" />
            <ErrorHandler
              :error="error"
              :errorMessage="errorMessage"
              :missingRequiredFieldWarn="$FORM('ERROR').MISSING_REQUIRED_FIELD"
              :emptyTabWarn="$FORM('ERROR').EMPTYTAB"
              :emptyPermittedGroupsWarn="$FORM('ERROR').EMPTY_PERMITTED_GROUPS"
              @addAllRequiredField="addAllRequiredField"
            />
            <div class="d-flex justify-content-between mt-4" v-if="!loading">
              <b-button @click="cancelSet" variant="danger">
                {{ $FORM('TEXT').WORD.CANCEL }}
              </b-button>
              <b-button type="submit" variant="success" @click="$refs.FormField.validateFormData()">
                {{ $FORM('TEXT').WORD.SET }}
              </b-button>
            </div>
          </b-card>
        </transition>
      </b-modal>
    </div>
    <!-- END OF MODAL FOR EDITING FORM -->

    <!-- MODAL FOR VALIDATION ERROR -->
    <b-modal id="modal-item-validation" :title="$FORM('ERROR').COMPOSITE_FORM.ERROR_MODAL_TITLE" ok-only>
      <p>
        {{ validationError.message }}
      </p>
      <ul v-if="validationError.message && validationError.list.length">
        <li v-for="item in validationError.list" :key="item">
          {{ item }}
        </li>
      </ul>
    </b-modal>
    <!-- END OF MODAL FOR VALIDATION ERROR -->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import ParentCanvas from '@/modules/insight/components/canvas/ParentCanvas'
import HeaderEdit from '@/modules/forms/components/HeaderEdit'
import FormField from '@/modules/forms/components/FormField'
import ErrorHandler from '@/modules/forms/components/ErrorHandler'
import FormBuilderMixin from '@/modules/forms/mixins/FormBuilderMixin'
import NavigationGuardMixin from '@/modules/insight/mixins/NavigationGuardMixin'
import GraphMixin from '@/modules/insight/mixins/GraphMixin'
import ToastMessage from '@/utils/toast_message'
import { find, isEmpty, cloneDeep } from 'lodash'

export default {
  name: 'create-edit-composite-form',
  components: {
    HeaderEdit,
    FormField,
    ErrorHandler,
    ParentCanvas
  },
  mixins: [FormBuilderMixin, NavigationGuardMixin, GraphMixin],
  data: () => ({
    loading: false,
    modalLoading: false,
    items: [],
    disableAccessGroupOption: true,
    accessGroupsError: '',
    errorMessage: false,
    formData: {},
    error: false,
    compositeForm: {
      title: '',
      model: null,
      permitted_groups: [],
      permitted_groups_option: [],
      forms: []
    },
    selectAllFormPermittedGroup: {
      data: true
    },
    currentItemTitle: '',
    actionKey: 0,
    individualFormError: false,
    maxEntityExtensions: Vue.prototype.$FORM('SETTINGS').COMPOSITE_FORM.MAX_ENTITY_EXTENSIONS,
    additionalPermittedGroups: [],
    slidingTab: {
      title: '',
      toggle: false
    },
    // Data for Validating Composite Forms
    currentItemModel: {},
    unConfirmedItem: [],
    exceededExtension: [],
    entitiesHasNoConnection: [],
    validationError: {
      message: '',
      list: []
    },
    formTitleError: {
      state: null,
      message: ''
    },
    linking: false
  }),
  computed: {
    ...mapState('forms', {
      compositeFormState: 'compositeForm',
      formsError: 'error'
    }),
    titleText () {
      if (this.mode === this.$FORM('MODE').EDIT_COMPOSITE_FORM) {
        return this.$FORM('TEXT').TITLE.EDIT_FORM
      } else {
        return this.$FORM('TEXT').TITLE.CREATE_FORM
      }
    },
    mode () {
      return this.$route.meta.action === 'create-composite-form' ? this.$FORM('MODE').CREATE_COMPOSITE_FORM : this.$FORM('MODE').EDIT_COMPOSITE_FORM
    }
  },
  async mounted () {
    this.fetchFormData()
  },
  methods: {
    ...mapActions('forms', [
      'getForm',
      'getFormFields',
      'createCompositeForm',
      'updateForm',
      'choicesPermittedGroup'
    ]),
    async fetchFormData () {
      const isCreate = this.mode.includes('create')
      const fetchPermittedGroupsOption = await this.getFormFields({ isCreate, isCompositeForm: true })
      const permittedGroupsOption = fetchPermittedGroupsOption?.permitted_groups_option
      for (const option in permittedGroupsOption) {
        this.compositeForm.permitted_groups_option.push(permittedGroupsOption[option])
      }
      this.slidingTab.toggle = true
      if (this.$route.meta.action === 'edit-composite-form') {
        this.loading = true
        await this.getForm({ id: this.$route.params.id })
        this.items = this.compositeFormState.forms
        for (const item of this.items) {
          item.confirmed = true
          item.name = item.title
          item.formId = item.id
        }
        this.compositeForm.title = this.compositeFormState.title
        this.compositeForm.permitted_groups = this.compositeFormState.permitted_groups
          .filter(gid => this.compositeForm.permitted_groups_option.includes(gid))
        this.additionalPermittedGroups = this.compositeFormState.permitted_groups
          .filter(gid => !this.compositeForm.permitted_groups_option.includes(gid))
        this.loading = false
        this.queryToggle = false
      } else {
        this.compositeForm.permitted_groups = cloneDeep(this.compositeForm.permitted_groups_option)
        this.queryToggle = true
      }
      await this.setFormPermittedGroupsOption(fetchPermittedGroupsOption?.permitted_groups_option)
      this.setNavigationGuard()
      this.loading = false
    },
    async submitCompositeForm () {
      let submitted = false
      const valid = await this.validateForms()
      if (valid) {
        this.loading = true
        submitted = await this.confirmSubmit()
        this.loading = false
      }
      if (submitted) {
        this.setCanLeavePage(true)
        if (this.mode === this.$FORM('MODE').EDIT_COMPOSITE_FORM) {
          ToastMessage.showEditedSuccess({
            vueInstance: this,
            name: this.compositeForm.title
          })
        } else {
          ToastMessage.showCreatedSuccess({
            vueInstance: this,
            name: this.compositeForm.title
          })
        }
        this.goBack()
      } else {
        return false
      }
      this.loading = false
    },
    validateForms () {
      const items = this.createStructuredGraph()
      const entitiesAndLinks = items.entities.concat(items.links)
      this.unConfirmedItem = []
      this.exceededExtension = []
      this.entitiesHasNoConnection = []
      const entitiesHasConnection = []
      const separatedEntityList = []
      let brokenGraph

      // check is primary defined
      const primaryDefined = items.primary

      // check if title is blank
      const titleIsBlank = !this.compositeForm.title

      // check if items are empty
      const noItem = isEmpty(items.entities) && isEmpty(items.links)

      // check if permitted_groups are empty
      const noPermittedGroup = !this.compositeForm.permitted_groups.length

      // check whether unConfirmedItem exists
      entitiesAndLinks.forEach(item => {
        if (!item.confirmed) this.unConfirmedItem.push(item.name)
      })

      // check whether entity without link exists
      items.links.forEach(link => {
        entitiesHasConnection.push(link.end1)
        entitiesHasConnection.push(link.end2)
      })
      items.entities.forEach(entity => {
        if (!(entitiesHasConnection.includes(entity.name))) {
          this.entitiesHasNoConnection.push(entity.name)
        }

        // check if is graph broken
        const mainGraph = items.paths?.visited
        const entityId = entity.id.toString() // item.paths.visited always return node name as string
        if (!mainGraph?.has(entityId)) {
          brokenGraph = true
          separatedEntityList.push(entity.name)
        }
      })

      // check whether primary has extensions more than allowed
      const BFSPaths = items.paths
      if (BFSPaths) {
        const extensionDistance = Object.entries(items.paths.distanceFromSource)
        for (const [id, distance] of extensionDistance) {
          if (distance > this.maxEntityExtensions) {
            const exceededEntity = find(items.entities, ['id', parseInt(id)])
            this.exceededExtension.push(exceededEntity.name)
          }
        }
      }

      const noItemAlert = this.$FORM('ERROR').COMPOSITE_FORM.NO_ITEM
      const noPermittedGroupAlert = this.$FORM('ERROR').EMPTY_PERMITTED_GROUPS
      const undefinedPrimary = this.$FORM('ERROR').COMPOSITE_FORM.UNDEFINED_PRIMARY
      const entityNotLinkedExists = this.$FORM('ERROR').COMPOSITE_FORM.ENTITY_NOT_LINKED_EXISTS
      const unconfirmedExists = this.$FORM('ERROR').COMPOSITE_FORM.UNCONFIRMED_ITEM_EXISTS
      const titleIsBlankError = this.$FORM('ERROR').TITLE_IS_REQUIRED
      const brokenGraphError = this.$FORM('ERROR').COMPOSITE_FORM.BROKEN_GRAPH_MESSAGE

      const rawLimitExceededMsg = this.$FORM('ERROR').COMPOSITE_FORM.EXTENSIONS_LIMIT_EXCEEDED
      const maxEntityVariable = this.$FORM('ERROR').COMPOSITE_FORM.MAX_ENTITY_VARIABLE
      const limitExceededDynamicMsg = rawLimitExceededMsg.replace(maxEntityVariable, this.maxEntityExtensions)

      const error = { message: '', list: [] }

      if (noItem) {
        error.message = noItemAlert
      } else if (!primaryDefined) {
        error.message = undefinedPrimary
      } else if (this.entitiesHasNoConnection.length) {
        error.message = entityNotLinkedExists
        error.list = this.entitiesHasNoConnection
      } else if (brokenGraph) {
        error.message = brokenGraphError
        error.list = separatedEntityList
      } else if (this.unConfirmedItem.length) {
        error.message = unconfirmedExists
        error.list = this.unConfirmedItem
      } else if (this.exceededExtension.length) {
        error.message = limitExceededDynamicMsg
        error.list = this.exceededExtension
      } else if (noPermittedGroup) {
        error.message = noPermittedGroupAlert
        this.slidingTab.toggle = true
        this.accessGroupsError = true
      } else if (titleIsBlank) {
        this.setTitleErrorMessage(titleIsBlankError, true) // the modal will be opened by this function
        return false
      }

      if (error.message) {
        this.$bvModal.show('modal-item-validation')
        this.validationError = error
        return false
      } else {
        return true
      }
    },
    async confirmSubmit () {
      // later will move to submitCompositeForm Function
      this.compositeForm.forms = []
      this.compositeForm.type = 'C'
      this.compositeForm.permitted_groups = this.compositeForm.permitted_groups.concat(this.additionalPermittedGroups)
      const moveKeyOneLevel = ['title', 'groups', 'model']
      const items = JSON.parse(JSON.stringify(this.items)) // clonedeep
      items.forEach(item => {
        if (item.formData) {
          moveKeyOneLevel.forEach(key => {
            item[key] = item.formData[key]
          })
        }
        const formattedItemData = this.cleanFormDataForChildForm(item)
        this.compositeForm.forms.push(formattedItemData)
        delete item.formData
      })
      if (this.mode === this.$FORM('MODE').EDIT_COMPOSITE_FORM) {
        const submitData = await this.updateForm({
          id: this.$route.params.id,
          data: this.compositeForm
        })
        return submitData
      } else {
        const submitForm = await this.createCompositeForm(this.compositeForm)
        return submitForm
      }
    },
    changeItemsDirectly (data) {
      this.items = data
    },
    cancelSet () {
      this.$bvModal.hide('create-form-modal')
    },
    addAllRequiredField () {
      this.$refs.FormField.addAllRequiredField()
    },
    resetError () {
      this.errorMessage = false
      this.error = false
      this.individualFormError = false
    },
    setError (error) {
      this.error = error
    },
    getLinkByTitle (title) {
      return this.items.filter(l => l.end1 === title || l.end2 === title)
    },
    updateTitle (item) {
      const oldTitle = this.currentItemTitle
      const newTitle = item.formData.title
      const links = this.getLinkByTitle(oldTitle)
      for (const link of links) {
        if (link.end1 === oldTitle) {
          link.end1 = newTitle
        }

        if (link.end2 === oldTitle) {
          link.end2 = newTitle
        }
      }
      if (links.length > 0) {
        item.title = newTitle
        item.formData.title = newTitle
      }
    },
    submitDynamicForm () {
      if (!this.isTitleExist(this.currentItemModel.id, this.formData)) {
        this.individualFormError = false
        this.$bvModal.hide('create-form-modal')
        this.currentItemModel.name = this.formData.title
        this.currentItemModel.formData = JSON.parse(JSON.stringify(this.formData))
        this.currentItemModel.confirmed = true
        this.updateTitle(this.currentItemModel)
        this.actionKey += 1
      } else {
        this.setError(true)
        this.individualFormError = {
          message: {
            title: [this.$FORM('ERROR').TITLE_ALREADY_EXISTS]
          }
        }
      }
    },
    async openFormModal (item) {
      this.resetError()
      this.formData = {}
      this.currentItemModel = item
      this.currentItemTitle = item.name
      this.modalLoading = true
      this.$bvModal.show('create-form-modal')
      const modelType = item.model
      const formData = await this.getFormFields({ modelType })
      formData.title = item.name
      formData.model_fields.forEach(item => {
        if (item.validation?.required === true) item.label = `${item.label}*`
      })
      this.formData = item.formData ? JSON.parse(JSON.stringify(item.formData)) : formData
      if (item.groups) {
        this.formData.groups = item.groups
      }
      this.modalLoading = false
    },
    setPrimary (item) {
      if (item.primary) {
        item.primary = false
      } else {
        this.items.forEach((item) => { item.primary = false }) // set other item.primary to false if exists
        item.primary = true
        item.isMandatory = true
      }
    },
    setMandatory (item) {
      item.isMandatory = !item.isMandatory
    },
    isTitleExist (currentId, formData) {
      return this.items.filter(item => item.id !== currentId)
        .filter(item => (item.title === formData.title || item.name === formData.title)).length > 0
    },
    setTitleErrorMessage (error, frontEndValidation) {
      const errMessage = frontEndValidation ? error : error.message.title[0]
      this.validationError = { message: `${this.$FORM('TEXT').TITLE.FORM_TITLE} : ${errMessage}`, list: [] }
      this.$bvModal.show('modal-item-validation')
      this.formTitleError.message = errMessage
      this.formTitleError.state = false
      this.slidingTab.toggle = true
    },
    setNavigationGuard () {
      // To set data in NavigationGuardMixin
      const comparedData = {
        items: this.items,
        compositeForm: this.compositeForm
      }
      this.comparedData = comparedData
      this.canLeavePage = false
      this.initialData = JSON.stringify(this.comparedData)
    },
    manipulateItems () {
      const structuredGraph = this.createStructuredGraph()
      const onlyOneItemLeft = structuredGraph.entities.length === 1
      const listOfEntities = structuredGraph.entities

      // SET ONE ITEM LEFT IN THE GRAPH TO BE A PRIMARY AND MANDATORY FORM
      if (onlyOneItemLeft) {
        this.items[0].primary = true
        this.items[0].isMandatory = true
      }

      listOfEntities.forEach(item => {
        this.checkMandatoryItem(item)
      })
    },
    checkMandatoryItem (item) {
      const structuredGraph = this.createStructuredGraph()
      const BFSPath = structuredGraph.paths

      let parentItem = false
      let parentIsMandatory = false
      let childIsMandatory = false

      if (BFSPath) {
        const childItemList = this.getChildItems(item)

        parentItem = this.getParentItem(item)
        parentIsMandatory = parentItem?.isMandatory

        // CHECK CHILD ITEM IF IT'S MANDATORY
        childItemList.forEach(child => {
          if (child?.isMandatory) {
            childIsMandatory = true
          }
        })
      }

      // RULES
      const itemIsMandatoryButParentIsNot = !item.primary && item.isMandatory && !parentIsMandatory && parentItem
      const itemIsNotMandatoryButParentAndChildIs = parentIsMandatory && childIsMandatory && !item.isMandatory
      const parentAndChildIsMandatory = parentIsMandatory && childIsMandatory
      const parentIsMandatoryButChildIsNot = parentIsMandatory && !childIsMandatory

      if (itemIsMandatoryButParentIsNot) {
        parentItem.isMandatory = true
      } else if (itemIsNotMandatoryButParentAndChildIs) {
        item.isMandatory = true
        item.canSetAsMandatory = false
      } else if (parentAndChildIsMandatory) {
        item.canSetAsMandatory = false
      } else if (parentIsMandatoryButChildIsNot) {
        item.canSetAsMandatory = true
      } else {
        item.canSetAsMandatory = false
      }
    },
    isLinking (linking) {
      this.linking = linking
    },
    setFormPermittedGroups (value) {
      this.compositeForm.permitted_groups = value
    },
    setFormPermittedGroupsOption (permittedGroupsOption) {
      this.$refs.ParentCanvas.setFormPermittedGroupsOption(permittedGroupsOption)
    }
  },
  watch: {
    error (err) {
      if (err) {
        this.loading = false
        this.errorMessage = this.$FORM('ERROR').VALIDATION_ERROR
      }
    },
    items: {
      immediate: true,
      deep: true,
      handler: function () {
        this.manipulateItems()
        this.graphItems = this.items
      }
    },
    formsError (error) {
      if (error.message?.title) {
        this.setTitleErrorMessage(error, false)
      } else if (error.message || error.code) {
        this.validationError.message = error.message
      }
    },
    compositeForm: {
      deep: true,
      handler: function (form) {
        this.formTitleError.state = null
        this.accessGroupsError = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.create-edit-composite-form {
  margin: -28px;
  display: flex;
  height: calc(100vh - 56px);
  position: relative;
}

</style>
