export default {
  data: () => ({
    // for removing certain key (in formData) on submit
    removeKeyForCreate: ['model_fields', 'model_type', 'model_label', 'permitted_groups_option'],
    removeKeyForEdit: ['created_by', 'created_on', 'linkend', 'updated_by', 'updated_on', 'model_type', 'model_label',
      'model_fields', 'model_choices'],
    removeKeyForEditModelFields: ['name', 'options'],
    removeKeyForChildForm: ['confirmed', 'model_choices', 'model_fields', 'linkend']
  }),
  methods: {
    cleanFormData (formData, isCreateForm) {
      // to remove unnecessary data when submitting form
      let removedKey
      if (isCreateForm) {
        removedKey = this.removeKeyForCreate
      } else {
        removedKey = this.removeKeyForEdit
        // to remove unnecessary data inside model_fields in groups
        formData.groups.forEach(group => {
          group.model_fields.forEach(modelField => {
            this.removeKeyForEditModelFields.forEach(key => {
              delete modelField[key]
            })
          })
        })
      }
      removedKey.forEach(key => {
        delete formData[key]
      })
      return formData
    },
    cleanFormDataForChildForm (formData) {
      this.removeKeyForChildForm.forEach(key => {
        delete formData[key]
      })
      return formData
    }
  }
}
