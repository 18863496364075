<template>
  <b-form-group
    class="d-flex justify-content-start"
  >
    <!-- PORTAL FOR DEFAULT FORM OPTIONS TITLE -->
    <portal to="formbuilder-defaultform-card-header">
      <h6>
        {{$FORM('TEXT').DEFAULT_FORM.SET_DEFAULT_FORM.PHRASE_1}}
        <LaInfoCircle v-b-tooltip.hover :title="toolTipMessage" class="info-circle" />
        {{$FORM('TEXT').DEFAULT_FORM.SET_DEFAULT_FORM.PHRASE_2}}
      </h6>
    </portal>
    <!-- END OF PORTAL FOR DEFAULT FORM OPTIONS TITLE -->
    <span v-if="defaultFormGroupsOptions.length">
      <b-form-checkbox @change="selectAllGroups" v-model="selectAll">
        {{$FORM('TEXT').PERMITTED_GROUP.SELECT_ALL_GROUP }}
      </b-form-checkbox>
      <b-form-checkbox-group
        stacked
        class="ml-2"
        v-model="defaultFormGroups"
        :options="defaultFormGroupsOptions"
        checkboxes
      />
    </span>
    <div class="d-flex flex-column justify-content-center" v-else>
      <span class="empty-permitted-group-warning">
        {{$FORM('TEXT').DEFAULT_FORM.EMPTY_PERMITTED_GROUPS}}
      </span>
    </div>
  </b-form-group>
</template>

<script>
import LaInfoCircle from '@/assets/la-info-circle.svg'
import { isEmpty } from 'lodash'

export default {
  name: 'default-form',
  components: {
    LaInfoCircle
  },
  props: {
    defaultFormGroupsOptions: {
      default: () => []
    },
    entityLinkType: {
      default: ''
    }
  },
  computed: {
    toolTipMessage () {
      return `${this.$FORM('TEXT').DEFAULT_FORM.TOOLTIP_INFO.PHRASE_1} ${this.entityLinkType} ${this.$FORM('TEXT').DEFAULT_FORM.TOOLTIP_INFO.PHRASE_2}`
    }
  },
  data: () => ({
    defaultFormGroups: [],
    selectAll: false
  }),
  methods: {
    emitDefaultFormGroups (value) {
      this.$emit('setDefaultFormGroups', value)
    },
    selectAllGroups (selectAll) {
      this.defaultFormGroups = []
      if (selectAll) {
        this.defaultFormGroupsOptions.forEach(option => {
          this.defaultFormGroups.push(option.value)
        })
      }
    },
    checkIfSelectedAll () {
      const isSelectedAll = this.defaultFormGroups?.length === this.defaultFormGroupsOptions?.length
      this.selectAll = isSelectedAll
    },
    checkIfOptionsStillSame () {
      const defaultFormValue = this.defaultFormGroups
      const defaultFormOptions = this.defaultFormGroupsOptions
      const defaultFormOptionsId = []
      const defautFormValueAndOptionsNotEmpty = !isEmpty(defaultFormValue) && !isEmpty(defaultFormOptions)
      if (defautFormValueAndOptionsNotEmpty) {
        defaultFormOptions.forEach(item => { defaultFormOptionsId.push(item.value) })
        defaultFormValue.forEach((id, index) => {
          const notExistsInOptions = !defaultFormOptionsId.includes(id)
          if (notExistsInOptions) {
            this.defaultFormGroups.splice(index, 1)
          }
        })
      }
    }
  },
  watch: {
    defaultFormGroups: {
      immidiate: true,
      handler: function (value) {
        this.emitDefaultFormGroups(value)
        this.checkIfSelectedAll()
      }
    },
    defaultFormGroupsOptions: {
      immidiate: true,
      handler: function () {
        this.checkIfOptionsStillSame()
        this.checkIfSelectedAll()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-circle {
  height: 15px;
  margin: -12px 0px 0px -5px;
}

.empty-permitted-group-warning {
  font-size: 12px;
  text-align: center;
  color: grey;
}
</style>
