var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-list",attrs:{"id":"form-list"}},[_c('portal',{attrs:{"to":"top-bar"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$LOCAL('Forms')))])]),(_vm.hasPermission(_vm.$FORM('PERMISSIONS').CREATE))?_c('b-dropdown',{attrs:{"variant":"success","id":"create-btn","size":"sm","text":_vm.$LOCAL('ToolTipLabelNew')}},[_c('b-dropdown-item',{attrs:{"to":{ name: 'create-form' }}},[_vm._v(_vm._s(_vm.$FORM('TEXT').WORD.SINGLE)+" Entity/Link")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'create-composite-form' }}},[_vm._v(_vm._s(_vm.$FORM('TEXT').WORD.MULTIPLE)+" Entity/Link")])],1):_vm._e()],1),(
      _vm.showError !== false && _vm.loading === false && _vm.displayError.code !== 404
    )?_c('error-handler',{attrs:{"error":_vm.displayError,"showError":_vm.showError && _vm.displayError.code !== 404,"variant":"danger"},on:{"dismiss-error":function($event){_vm.showError = false}}}):_vm._e(),(_vm.hasPermission(_vm.$FORM('PERMISSIONS').READ))?_c('search-sort-controls',{ref:_vm.$FORM('REFS').SEARCH_FORM,staticClass:"search-and-sort-controls",attrs:{"loading":_vm.loading,"showSortAndRefresh":_vm.hasInitialised,"hasClearSearchCross":true,"hasAdvancedSearch":false,"hasModelSelection":false,"currentSentSearch":_vm.searchTerm,"searchCount":_vm.items.count ? _vm.items.count : 0,"searchNoun":_vm.$FORM('TEXT').WORD.FORMS,"sortFields":_vm.items.fields ? _vm.items.fields : {},"isForEntityOrLink":false,"sortCustomIdField":"id"},on:{"sort":_vm.sort,"search":_vm.doSearch,"refresh":function($event){return _vm.fetchFormList(false)}}}):_vm._e(),(_vm.hasInitialised === true)?_c('div',{staticClass:"results-area"},[_c('index-cards',{attrs:{"items":_vm.listOfItems,"searchQuery":_vm.searchTerm,"hasStar":"true","hiddenFields":_vm.hideField,"sortField":_vm.sortBy},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.goToCreatePage(item)}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"create",fn:function(ref){
    var item = ref.item;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:form-list.hover",arg:"form-list",modifiers:{"hover":true}}],class:{ 'cannot-create' : !_vm.canAccessForm(item) },attrs:{"title":_vm.createTooltipLabel(item),"variant":"success","to":_vm.goToCreatePage(item)}},[_c('LaPlus',{staticClass:"white-svg",attrs:{"height":"20px"}})],1)]}},{key:"edit",fn:function(ref){
    var item = ref.item;
return [(_vm.hasPermission(_vm.$FORM('PERMISSIONS').UPDATE))?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:form-list.hover",arg:"form-list",modifiers:{"hover":true}}],attrs:{"title":_vm.$LOCAL('ToolTipLabelEdit'),"variant":"primary","to":_vm.goToEditPage(item)}},[_c('LaEdit',{attrs:{"height":"20px"}})],1):_vm._e()]}},{key:"delete",fn:function(ref){
    var item = ref.item;
return [(_vm.hasPermission(_vm.$FORM('PERMISSIONS').DELETE))?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:form-list.hover",arg:"form-list",modifiers:{"hover":true}}],attrs:{"title":_vm.$LOCAL('ToolTipLabelDelete'),"variant":"danger"},on:{"click":function($event){return _vm.deleteThisRecord(item)}}},[_c('LaTrash',{attrs:{"height":"20px"}})],1):_vm._e()]}},{key:"star",fn:function(ref){
    var item = ref.item;
return [_c('star',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:form-list.hover",arg:"form-list",modifiers:{"hover":true}}],staticClass:"star-icon cursor-pointer",attrs:{"id":item.id + '-star',"height":"20px","title":_vm.$LOCAL('ToolTipLabelStar')},on:{"click":function($event){_vm.toggleFavourite(_vm.$FORM('FAVOURITE').TYPE, item.id)}}})]}}],null,false,4160986910)})],1):_vm._e(),(_vm.items.next)?_c('b-button',{staticClass:"load-more-button",attrs:{"variant":"outline"},on:{"click":function($event){return _vm.fetchFormList(true)}}},[(_vm.loading === false)?_c('b-badge',{attrs:{"pill":"","variant":"secondary"}},[_vm._v(_vm._s(_vm.$LOCAL('LoadMore')))]):_vm._e(),(_vm.loading === true)?_c('b-spinner',{attrs:{"small":"","label":_vm.$FORM('TEXT').WORD.LOADING}}):_vm._e()],1):_vm._e(),_c('delete-modal',{ref:_vm.$FORM('REFS').DELETE_MODAL,attrs:{"item":"","itemId":_vm.formToDelete + ' Form',"modalShow":_vm.showDeleteModal},on:{"ok":_vm.confirmDelete,"hide":_vm.cancelDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }