<template>
  <div class="form-list" id="form-list">
    <portal to="top-bar">
      <div class="title">
        <h1>{{ $LOCAL('Forms') }}</h1>
      </div>
        <b-dropdown variant="success" id="create-btn" size="sm" :text="$LOCAL('ToolTipLabelNew')"
                    v-if="hasPermission($FORM('PERMISSIONS').CREATE)">
          <b-dropdown-item :to="{ name: 'create-form' }">{{ $FORM('TEXT').WORD.SINGLE }} Entity/Link</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'create-composite-form' }">{{ $FORM('TEXT').WORD.MULTIPLE }} Entity/Link</b-dropdown-item>
        </b-dropdown>
    </portal>

    <error-handler
      v-if="
        showError !== false && loading === false && displayError.code !== 404
      "
      :error="displayError"
      :showError="showError && displayError.code !== 404"
      variant="danger"
      @dismiss-error="showError = false"
    />

    <search-sort-controls
      v-if="hasPermission($FORM('PERMISSIONS').READ)"
      class="search-and-sort-controls"
      :ref="$FORM('REFS').SEARCH_FORM"
      :loading="loading"
      :showSortAndRefresh="hasInitialised"
      :hasClearSearchCross="true"
      :hasAdvancedSearch="false"
      :hasModelSelection="false"
      :currentSentSearch="searchTerm"
      :searchCount="items.count ? items.count : 0"
      :searchNoun="$FORM('TEXT').WORD.FORMS"
      :sortFields="items.fields ? items.fields : {}"
      :isForEntityOrLink="false"
      sortCustomIdField="id"
      @sort="sort"
      @search="doSearch"
      @refresh="fetchFormList(false)"
    />

    <!-- Component not used -->
    <!-- View permission error is handled differently as compared to Dashboard and Queries. Consider reimplementing the error handling here? -->
    <!-- <error-handler v-if="error !== false && loading === false" :error="error"/> -->

    <div class="results-area" v-if="hasInitialised === true">
      <index-cards
        :items="listOfItems"
        :searchQuery="searchTerm"
        hasStar="true"
        :hiddenFields="hideField"
        :sortField="sortBy"
      >
        <template v-slot:name="{ item }">
          <router-link :to="goToCreatePage(item)">
            {{ item.title }}
          </router-link>
        </template>
        <template v-slot:create="{ item }">
          <b-btn v-b-tooltip:form-list.hover :title="createTooltipLabel(item)"
                 :class="{ 'cannot-create' : !canAccessForm(item) }" variant="success"
                 :to="goToCreatePage(item)">
            <LaPlus height="20px" class="white-svg" />
          </b-btn>
        </template>
        <template v-slot:edit="{ item }">
          <b-btn v-b-tooltip:form-list.hover :title="$LOCAL('ToolTipLabelEdit')"
                 v-if="hasPermission($FORM('PERMISSIONS').UPDATE)" variant="primary"
                 :to="goToEditPage(item)">
            <LaEdit height="20px"/>
          </b-btn>
        </template>
        <template v-slot:delete="{ item }">
          <b-btn
            v-b-tooltip:form-list.hover
            :title="$LOCAL('ToolTipLabelDelete')"
            v-if="hasPermission($FORM('PERMISSIONS').DELETE)"
            @click="deleteThisRecord(item)"
            variant="danger"
          >
            <LaTrash height="20px" />
          </b-btn>
        </template>
        <template v-slot:star="{ item }">
          <star
            :id="item.id + '-star'"
            height="20px"
            class="star-icon cursor-pointer"
            v-b-tooltip:form-list.hover
            :title="$LOCAL('ToolTipLabelStar')"
            @click="toggleFavourite($FORM('FAVOURITE').TYPE, item.id)"
          />
        </template>
      </index-cards>
    </div>
    <b-button
      v-if="items.next"
      class="load-more-button"
      variant="outline"
      @click="fetchFormList(true)"
    >
      <b-badge pill variant="secondary" v-if="loading === false">{{
        $LOCAL('LoadMore')
      }}</b-badge>
      <b-spinner
        small
        v-if="loading === true"
        :label="$FORM('TEXT').WORD.LOADING"
      />
    </b-button>

    <delete-modal
      item=""
      :itemId="formToDelete + ' Form'"
      :modalShow="showDeleteModal"
      @ok="confirmDelete"
      @hide="cancelDelete"
      :ref="$FORM('REFS').DELETE_MODAL"
    >
    </delete-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import LaEdit from '@/assets/la-edit.svg'
import LaPlus from '@/assets/la-plus.svg'
import LaTrash from '@/assets/la-trash.svg'
import DeleteModal from '@/modules/insight/components/DeleteModal'
import IndexCards from '@/modules/insight/components/IndexCards'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import Star from '@/assets/star-solid.svg'
import DeleteFormMixin from '@/modules/forms/mixins/DeleteFormMixin'
import FavouriteMixin from '@/modules/forms/mixins/FavouriteMixin'
import { isEmpty } from 'lodash'

export default {
  name: 'forms',
  components: {
    ErrorHandler,
    IndexCards,
    SearchSortControls,
    Star,
    LaEdit,
    LaPlus,
    LaTrash,
    DeleteModal
  },
  mixins: [DeleteFormMixin, FavouriteMixin],
  data: () => ({
    error: false,
    loading: false,
    idToDelete: '',
    formToDelete: '',
    showDeleteModal: false,
    item: false,
    page: 1,
    sortBy: ['-created_on', 'id'],
    searchTerm: '',
    hideField: [
      'permitted_groups',
      'model_type',
      'model_label',
      'model',
      'created_on',
      'updated_on',
      'type',
      'list_of_multiple_models'
    ],
    showError: false,
    displayError: {
      code: -1,
      message: ''
    }
  }),
  computed: {
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('forms', {
      items: 'forms',
      formError: 'error',
      userPermissions: 'userPermissions'
    }),
    ...mapState('insight', {
      entities: 'entities',
      links: 'links',
      insightError: 'error'
    }),
    hasInitialised () {
      return typeof this.items.results === 'object'
    },
    listOfItems () {
      const mapping = { C: this.$FORM('TEXT').WORD.MULTIPLE, S: this.$FORM('TEXT').WORD.SINGLE }
      this.items.results.forEach((item, index) => {
        // Convert type code to word
        item[this.$FORM('HEADER_TYPE').FORM_TYPE] = mapping[item.type] || item.type
        item[item.model_type] = item.model_label
      })
      return this.items.results
    }
  },
  async mounted () {
    // to not triggering the cleanItems function in getEntities and getLinks action
    await this.getEntities(false)
    await this.getLinks(false)
    await this.getPermissions()
    await this.fetchFormList()
  },
  watch: {
    formError: {
      handler: function (newError) {
        if (newError !== false) {
          this.showError = true
          this.loading = false
          this.displayError = {
            code: newError.code,
            message: newError.message.detail
              ? newError.message.detail
              : newError.message
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('forms', [
      'getFormList',
      'choicesPermittedGroup',
      'getPermissions'
    ]),
    ...mapActions('insight', ['getEntities', 'getLinks']),
    async fetchFormList (isAppend = false) {
      this.loading = true
      if (isAppend) {
        this.page++
      } else {
        this.page = 1
      }
      const params = {
        page: this.page,
        search: this.searchTerm,
        ordering: this.sortBy.join(',')
      }
      await this.getFormList({ params: params, isAppend: isAppend })
      if (!this.showError) {
        await this.checkFavourited(
          this.items.results,
          this.$FORM('FAVOURITE').TYPE
        )
      }
      this.loading = false
    },
    doSearch (searchTerm) {
      this.searchTerm = searchTerm
      this.fetchFormList(false)
    },
    cancelDelete () {
      this.showDeleteModal = false
    },
    deleteThisRecord (item) {
      if (item) {
        this.idToDelete = item.id
        this.formToDelete = item.title
        this.showDeleteModal = true
      } else {
        this.idToDelete = ''
        this.formToDelete = ''
        this.showDeleteModal = false
      }
    },
    async confirmDelete () {
      await this.processDelete(this.idToDelete, this.formToDelete)
      await this.fetchFormList()
    },
    canAccessForm (item) {
      const multipleModels = item?.list_of_multiple_models
      const hasMultipleModels = !isEmpty(multipleModels)
      // have multiple forms => composite form
      if (hasMultipleModels) {
        // returning true if user can access form using validate every model need to have the permission to create record
        return multipleModels.every(model => {
          let isPermittedToCreate = true
          const entity = this.entities[model]
          const link = this.links[model]
          const permissions = entity?.permissions || link?.permissions
          const isPermissionsExists = !isEmpty(permissions)
          if (isPermissionsExists) {
            isPermittedToCreate = !permissions.find(permission =>
              permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').CREATE)
            )
          }
          return isPermittedToCreate
        })
      } else {
        // if single form
        const modelData =
          item.model_type === 'Entity' ? this.entities : this.links
        const thisForm = modelData[item.model]
        if (!thisForm) { // if you can't get the form you can't access it
          return false
        }
        const permissions = thisForm.permissions
        return !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').CREATE)
        )
      }
    },
    hasPermission (action) {
      if (
        this.userPermissions.includes(`dynamic_forms.${action}_dynamicform`)
      ) {
        return true
      }
      return false
    },
    sort (sortBy) {
      this.sortBy = sortBy
      this.fetchFormList(false)
    },
    goToCreatePage (itemData) {
      const entitiesStaticWord = this.$FORM('FORM_TYPE').CREATE_RECORD_DIRECTION.ENTITIES
      const cannotAccessCreatePage = !this.canAccessForm(itemData)
      if (cannotAccessCreatePage) {
        return {}
      } else if (this.isCompositeForm(itemData)) {
        const compositeStaticWord = this.$FORM('FORM_TYPE').COMPOSITE
        return {
          path: `${compositeStaticWord}/${entitiesStaticWord}/create/${itemData.id}`
        }
      } else if (
        itemData.model_type.toLowerCase() === this.$FORM('FORM_TYPE').ENTITY
      ) {
        return {
          path: `/${entitiesStaticWord}/${itemData.model}/create/${itemData.id}`
        }
      } else {
        const linksStaticWord = this.$FORM('FORM_TYPE').CREATE_RECORD_DIRECTION
          .LINKS
        return {
          path: `/${linksStaticWord}/${itemData.model}/create/${itemData.id}`
        }
      }
    },
    goToEditPage (itemData) {
      if (this.isCompositeForm(itemData)) {
        return { name: 'edit-composite-form', params: { id: itemData.id } }
      } else {
        return { name: 'form-builder', params: { id: itemData.id } }
      }
    },
    isCompositeForm (itemData) {
      return itemData.type === this.$FORM('FORM_TYPE').COMPOSITE_CODE
    },
    createTooltipLabel (itemData) {
      if (!this.canAccessForm(itemData)) {
        return this.$LOCAL('CANNOT_CREATE_RECORDS')
      } else {
        let createType = this.$LOCAL('COMMON_WORD').RECORDS
        if (!this.isCompositeForm(itemData)) {
          createType = itemData.model_label
        }
        return this.$LOCAL('ToolTipLabelNew') + ' ' + createType
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-list {
  position: relative;
}

.search-and-sort-controls {
  padding: 0 14px;
}

.results-area {
  margin-top: -15px;
}

.star-icon[data-favourite='favourite'] {
  fill: $favourited;
}

.star-icon[data-favourite='mandatory'] {
  display: none;
}

.cannot-create {
  background-color: #28a74587;
  border-color: transparent;
}
</style>
