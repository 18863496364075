<template>
  <div>
    <b-alert variant="danger" :show="showError" class="mt-2 text-center d-flex flex-column">
      {{errorMessage}}
      <span v-if="[missingRequiredFieldWarn, emptyTabWarn, emptyPermittedGroupsWarn].includes(error)" class="font-weight-bold font-italic">
        {{error}}
        <b-button v-if="error === missingRequiredFieldWarn" @click="addAllRequiredField">
          {{$FORM('TEXT').BUTTON.ADD_ALL_REQUIRED_FIELDS}}
        </b-button>
      </span>
      <span v-else-if="!(errMessageParsed.includes($FORM('ERROR').CONDITIONS_MET.EMPTY_REQUIRED_FIELD))" class="font-weight-bold font-italic">
        {{errMessageParsed}}
      </span>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      default: false
    },
    errorMessage: {
      default: false
    },
    missingRequiredFieldWarn: {
      default: false
    },
    emptyTabWarn: {
      default: false
    },
    emptyPermittedGroupsWarn: {
      default: ''
    }
  },
  name: 'error-handler',
  computed: {
    showError () {
      return !!this.error
    },
    errMessageParsed () {
      if (!this.error?.message) {
        return ''
      } else if (typeof this.error.message === 'string') {
        return this.error.message
      } else {
        const messageIsString = typeof this.error.message === 'string'
        const parsed = Object.values(this.error.message)
        const message = []
        parsed.forEach(error => {
          message.push(error)
        })
        return messageIsString ? this.error.message : message.join()
      }
    }
  },
  methods: {
    addAllRequiredField () {
      this.$emit(this.$FORM('EMIT_EVENT').ADD_ALL_REQUIRED_FIELD, true)
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 1em;
  height: auto;

  path {
    fill: #fff;
  }
}
</style>
