<template>
  <div class="root d-flex flex-row justify-content-center">
    <portal to="top-bar">
      <div class="title">
        <h1>{{titleText}}</h1>
        <b-spinner v-if="!titleText" small />
      </div>
    </portal>

    <b-modal id="change-model-type" size="xl" no-close-on-esc hide-header hide-footer :no-close-on-backdrop="mode === $FORM('MODE').CREATE_FORM" :title="$FORM('TEXT').TITLE.CHOOSE_FORM_TYPE">
      <SourceDBMapping usage="formBuilder" :selectedFormModel="formData.model" @selectModelType="selectModelType" @cancelChoose="cancelChoose" />
    </b-modal>
<!--    TODO: Need to refactor into one component? Because it used in composite form also -->
      <b-overlay :show="loading" class="body-parent">
        <b-card class="shadow-sm text-left va-card">
          <template v-slot:header>
            <div>
              <span @click="showChangeModelType()" class="d-flex flex-row justify-content-start cursor-pointer">
                <h2 class="mb-0 form-name-card">{{ formNameCard }}</h2>
                <div class="edit-button"></div>
              </span>
            </div>
          </template>
          <HeaderEdit
            class="d-flex justify-content-center mb-2"
            :formMode="mode"
            :theHeader="formData"
            :error="error"
            :headerOf="$FORM('HEADER_TYPE').FORM"
            :enableFormBuilder="enableFormBuilder"
            @resetError="resetError" />
          <FormField
            :ref="$FORM('REFS').FORM_FIELD"
            :loading="loading"
            :mode="mode"
            :formData="formData"
            :error="error"
            :missingRequiredFieldWarn="missingRequiredFieldWarn"
            :emptyTabWarn="emptyTabWarn"
            :emptyPermittedGroupsWarn="emptyPermittedGroupsWarn"
            :enableFormBuilder="enableFormBuilder"
            @submitDynamicForm="submitDynamicForm"
            @setError="setError"
            @resetError="resetError" />
          <div class="group-option d-flex flex-row justify-content-around">
            <div class="group-option-card">
              <div class="group-option-header">
                <h6>{{$FORM('TEXT').PERMITTED_GROUP.TITLE}}</h6>
              </div>
              <PermittedGroup :parentData="formData" :ref="$FORM('REFS').PERMITTED_GROUP" @changePermittedGroup="changePermittedGroup" class="group-option-body p-2" />
            </div>

            <div class="group-option-card">
              <div class="group-option-header">
                <portal-target name="formbuilder-defaultform-card-header" />
              </div>
              <DefaultForm ref="DefaultForm" :defaultFormGroupsOptions="defaultFormGroupsOptions" @setDefaultFormGroups="setDefaultFormGroups" class="group-option-body p-2"/>
            </div>
          </div>
          <ErrorHandler
            :error="error"
            :errorMessage="errorMessage"
            :missingRequiredFieldWarn="missingRequiredFieldWarn"
            :emptyTabWarn="emptyTabWarn"
            :emptyPermittedGroupsWarn="emptyPermittedGroupsWarn"
            @addAllRequiredField="addAllRequiredField"
          />
          <div class="d-flex justify-content-between mt-4" v-if="!loading">
            <b-button @click="goBack" variant="danger">
              {{ $FORM('TEXT').WORD.CANCEL }}
            </b-button>
            <span id="submit-button">
              <!-- need a span wrapper to activate tooltip when disabled -->
              <b-button id="submit-button" :disabled="isSubmitButtonDisabled" type="submit" variant="success" @click="submitData">
                {{ $FORM('TEXT').WORD.SUBMIT }}
              </b-button>
            </span>
            <b-tooltip v-if="submitButtonTooltip" target="submit-button">{{submitButtonTooltip}}</b-tooltip>
          </div>
        </b-card>
      </b-overlay>
    <b-modal
      ok-variant="success" :ok-title="$FORM('TEXT').BUTTON.CONFIRM_REMOVE" cancel-variant="danger"
      id="change-model-alert" :title="`${$FORM('TEXT').QUESTION.CHANGE_MODEL_TITLE}`"
      @ok="changeModel()">
      <p class="my-4">{{$FORM('TEXT').QUESTION.DISCARD_CHANGES_BODY}}</p>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

import { find, isEmpty, cloneDeep } from 'lodash'
import { stringToISO, nowISO, ISOtoString } from '@/modules/insight/utils'
import ToastMessage from '@/utils/toast_message'

import HeaderEdit from '@/modules/forms/components/HeaderEdit'
import FormField from '@/modules/forms/components/FormField'
import PermittedGroup from '@/modules/forms/components/PermittedGroup'
import DefaultForm from '@/modules/forms/components/DefaultForm'
import ErrorHandler from '@/modules/forms/components/ErrorHandler'

import DeleteFormMixin from '@/modules/forms/mixins/DeleteFormMixin'
import LinkFormFieldMixin from '@/modules/insight/mixins/LinkFormFieldMixin'
import FormBuilderMixin from '@/modules/forms/mixins/FormBuilderMixin'
import NavigationGuardMixin from '@/modules/insight/mixins/NavigationGuardMixin'

import SourceDBMapping from '@/modules/config/views/insight/SourceDBMapping'

export default {
  name: 'form-builder',
  components: {
    HeaderEdit,
    FormField,
    PermittedGroup,
    ErrorHandler,
    SourceDBMapping,
    DefaultForm
  },
  mixins: [FormBuilderMixin, DeleteFormMixin, LinkFormFieldMixin, NavigationGuardMixin],
  data: () => ({
    mode: Vue.prototype.$FORM('MODE').EDIT,
    loading: true,
    formData: {},
    error: false,
    missingRequiredFieldWarn: Vue.prototype.$FORM('ERROR').MISSING_REQUIRED_FIELD,
    emptyTabWarn: Vue.prototype.$FORM('ERROR').EMPTYTAB,
    emptyPermittedGroupsWarn: Vue.prototype.$FORM('ERROR').EMPTY_PERMITTED_GROUPS,
    notEntityModelError: Vue.prototype.$FORM('ERROR').NOT_AN_ENTITY,
    errorMessage: false,
    orConditionInAndParent: false,
    formNameCard: '',
    formType: false,
    defaultFormGroupsOptions: [],
    defaultFormGroups: [],
    // for create form
    initialModel: false,
    validPermittedGroups: [],
    additionalPermittedGroups: [],
    isSubmitButtonDisabled: false,
    submitButtonTooltip: false
  }),
  props: {
    presetEntity: [Object, undefined]
  },
  async mounted () {
    await this.getEntities()
    await this.getLinks()
    await this.checkMode()
  },
  computed: {
    ...mapState('forms', {
      formsError: 'error'
    }),
    ...mapState('insight', {
      entities: 'entities',
      links: 'links'
    }),
    ...mapState('auth', {
      userData: 'userData'
    }),
    titleText () {
      if (this.mode === this.$FORM('MODE').EDIT) {
        return this.$FORM('TEXT').TITLE.EDIT_FORM
      } else if (this.mode === this.$FORM('MODE').CREATE_FORM) {
        return this.$FORM('TEXT').TITLE.CREATE_FORM
      } else {
        return `${this.$FORM('TEXT').WORD.CREATE} ${this.formNameCard}`
      }
    },
    enableFormBuilder () {
      if ((this.mode === this.$FORM('MODE').EDIT) || (this.mode === this.$FORM('MODE').CREATE_FORM)) return true
      return false
    }
  },
  watch: {
    formsError (value) {
      this.error = value
      if (value.code === 404) {
        this.$router.push({ name: 'forms' })
      }
    },
    error (err) {
      if (err) {
        this.loading = false // stop loading when error occurred
        this.errorMessage = this.$FORM('ERROR').SUBMISSION_ERROR
      }
    }
  },
  methods: {
    ...mapActions('forms', [
      'getForm',
      'getFormFields',
      'createForm',
      'updateForm',
      'createModel'
    ]),
    ...mapActions('insight', ['getEntities', 'getLinks', 'resetRecords', 'clearError']),
    checkMode () {
      this.mode = this.$route.meta.mode
      if (this.$route.meta.mode === this.$FORM('MODE').CREATE_FORM) {
        this.$bvModal.show('change-model-type')
      } else {
        this.fetchFormData()
      }
    },
    async fetchFormData (modelType, changeModel) {
      try {
        let formData
        if ((this.mode === this.$FORM('MODE').CREATE_FORM) || changeModel) {
          formData = await this.getFormFields({ modelType })
          formData.title = changeModel ? this.formData.title : this.$FORM('TEXT').CREATE_FORM.NEW_FORM_NAME
        } else {
          formData = await this.getForm({ id: this.$route.params.id, params: { edit_mode: 1 } })
        }
        if (formData) {
          formData.model_fields.forEach(item => {
            if (item.validation?.required === true) item.label = `${item.label}*`
          })
          this.formData = formData
          this.loading = false
          await this.addOptions()
          this.setFormTitle(formData.model)
          this.$nextTick(async () => {
            if ((this.mode === this.$FORM('MODE').CREATE_FORM) || changeModel) {
              this.$refs.PermittedGroup.setPermittedGroupsOption(formData?.permitted_groups_option, true)
              this.$bvModal.hide('change-model-type')
            } else {
              const fetchPermittedGroupsOption = await this.getFormFields({ modelType: formData?.model, isCreate: false })
              this.$refs.PermittedGroup.permittedGroups = formData.permitted_groups
              this.$refs.PermittedGroup.setPermittedGroupsOption(fetchPermittedGroupsOption?.permitted_groups_option)
              // this.validPermittedGroups = this.$refs.PermittedGroup.permittedGroupsOption.map(choice => choice.value)
              // this.additionalPermittedGroups = this.formData.permitted_groups.filter(id => !this.validPermittedGroups.includes(id))
              // this.formData.permitted_groups = this.$refs.PermittedGroup.permittedGroupList.map(choice => choice.value)
              this.$refs.DefaultForm.defaultFormGroups = this.formData?.default_form_groups // to sync default_form_groups data
            }
            this.setNavigationGuard()
          })
        }
      } catch (error) {
        this.error = error
      }
    },
    submitData () {
      this.setValidDefaultFormGroups()
      this.$refs.FormField.validateFormData()
    },
    async addOptions () {
      try {
        this.formData.groups.forEach(group => {
          group.model_fields.forEach(modelField => {
            modelField.name = modelField.model_field
            modelField.options = this.getModelFieldData(modelField.model_field)
            modelField.options.value = this.parseData(modelField, true)
          })
        })
      } catch (error) {
        this.error = error
      }
    },
    // TODO Can use setValues from utils.
    parseData (field, initialFetch) {
      if (['date', 'time'].includes(field.options.type) && field.options.value) {
        const parsedValue = stringToISO(
          field.options.value,
          field.options.type
        )
        return parsedValue
      } else if (['Now', 'Today'].includes(field.options.default_value)) {
        return ISOtoString(nowISO(), field.options.type)
      } else if (!['', null, undefined].includes(field.options.default_value) && initialFetch) {
        return field.options.default_value
      } else if (!['', null, undefined].includes(field.options.value)) {
        return field.options.value
      }
    },
    async submitDynamicForm () {
      try {
        this.loading = true
        let submitData, actionType
        const isCreateForm = this.mode === this.$FORM('MODE').CREATE_FORM
        const clonedFormData = cloneDeep(this.formData)
        const formData = this.cleanFormData(clonedFormData, isCreateForm)
        if (isCreateForm) {
          formData.type = 'S'
          formData.primary = false
          submitData = await this.createForm(formData)
          actionType = 'showCreatedSuccess'
        } else {
          formData.permitted_groups.push(...this.additionalPermittedGroups)
          submitData = await this.updateForm({
            id: this.$route.params.id,
            data: formData
          })
          actionType = 'showEditedSuccess'
        }
        if (submitData) {
          ToastMessage[actionType]({
            vueInstance: this,
            name: formData.title
          })
          this.setCanLeavePage(true)
          this.goBack()
        }
      } catch (error) {
        this.error = error
      }
    },
    getModelFieldData (modelField) {
      if (modelField && this.formData.model_fields) {
        const fieldData = find(this.formData.model_fields, [
          'name',
          modelField
        ])
        return fieldData
      }
      return ''
    },
    addAllRequiredField () {
      this.$refs.FormField.addAllRequiredField()
    },
    setError (error) {
      this.error = error
    },
    resetError () {
      this.errorMessage = false
      this.error = false
    },
    async selectModelType (model) {
      if (this.formData.model === model) {
        this.$bvModal.hide('change-model-type')
        return false
      }
      const editModel = (this.mode === this.$FORM('MODE').EDIT) && model
      if (this.formData) {
        this.formData = { title: this.formData.title }
      }
      this.$refs[this.$FORM('REFS').FORM_FIELD].currentPosition = 0
      this.setFormTitle(model)
      this.fetchFormData(model, editModel)
    },
    cancelChoose () {
      if ((this.mode === this.$FORM('MODE').CREATE_FORM) && !this.formData?.model) {
        this.goBack()
      } else {
        this.$bvModal.hide('change-model-type')
      }
    },
    showChangeModelType () {
      this.$bvModal.show('change-model-type')
    },
    goBack () {
      this.$router.push(this.previousRoute)
    },
    setFormTitle (model) {
      const modelLabel = this.entities[model] ? this.entities[model].label : this.links[model].label
      this.formType = this.entities[model] ? 'entities' : 'links'
      this.formNameCard = modelLabel
    },
    changePermittedGroup (permittedGroups) {
      const permittedGroupsAreEmpty = isEmpty(permittedGroups)
      this.isSubmitButtonDisabled = permittedGroupsAreEmpty // disable submit button if no permitted group selected
      this.submitButtonTooltip = permittedGroupsAreEmpty ? this.$FORM('TEXT').PERMITTED_GROUP.SUBMIT_BUTTON_EMPTY_PERMITTED_GROUPS : false // set tooltip button if permitted group empty
      this.formData.permitted_groups = permittedGroups
      this.setDefaultFormGroupsOptions()
    },
    setNavigationGuard () {
      // To set data in NavigationGuardMixin
      this.canLeavePage = false
      this.comparedData = { formData: this.formData }
      this.initialData = JSON.stringify(this.comparedData)
      this.discardChangesTitle = `${this.$FORM('TEXT').QUESTION.DISCARD_CHANGES_TITLE} ${this.formData?.title} ?`
    },
    setDefaultFormGroupsOptions () {
      const permittedGroups = this.formData.permitted_groups
      const permittedGroupsOption = this.$refs?.PermittedGroup?.permittedGroupsOption
      const permittedGroupsIsEmpty = isEmpty(permittedGroups)
      const permittedGroupsOptionIsEmpty = isEmpty(permittedGroupsOption)

      if (permittedGroupsIsEmpty || permittedGroupsOptionIsEmpty) {
        this.defaultFormGroupsOptions = []
      } else {
        const validFormOptions = []

        permittedGroupsOption.forEach(option => {
          if (permittedGroups.includes(option.value)) {
            validFormOptions.push(option)
          }
        })

        this.defaultFormGroupsOptions = validFormOptions
      }
    },
    setValidDefaultFormGroups () {
      // TODO: remove this in the future, since the validation already done in <DefaultForm />
      const defaultForm = this.defaultFormGroups
      const permittedGroups = this.formData.permitted_groups
      const validDefaultForm = []
      if (!isEmpty(defaultForm)) {
        defaultForm.forEach(groupId => {
          if (permittedGroups.includes(groupId)) {
            validDefaultForm.push(groupId)
          }
        })
      }
      this.formData.default_form_groups = validDefaultForm
    },
    setDefaultFormGroups (value) {
      this.defaultFormGroups = value
    }
  }
}
</script>

<style lang="scss" scoped>
.body-parent {
  width: 85%;
}

.form-name-card {
  font-size: 1.2rem;
}

.edit-button {
  filter: invert(100%);
}

.group-option {
  border: 1px dashed #91b0b3;
  margin-top: 5px;
  padding: 13px;
  position: relative;
  min-height: 90px;
}

.group-option-card {
  width: 50%;
  margin: 5px;
}

.group-option-header {
  height: 25px;
}

.group-option-body {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  min-height: 115px;
}
</style>
